/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, Link } from 'gatsby'

// Components
import SEO from 'components/SEO'
import styled from 'styled-components'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import { Hero4, HeadingLayout, Teammate } from 'components/elements'

// Blog
import BlogContainer, { BlogConsumer } from 'components/shared/BlogContainer'
import BlogOverview from 'components/elements/BlogOverview'

// Data
import postInfo from 'src/graphql/postInfo'

const BackLink = styled(Link)`
  color: ${(props) => props.theme.color.text.main};
  text-decoration: underline;
  font-size: 16px;
`
const Content = styled(ParseContent)`
  color: #6b6b6b;
  font-size: 16px;
  line-height: 30px;
  h3,
  a {
    color: ${(props) => props.theme.color.face.secondary};
  }
  a {
    text-decoration: underline;
  }
`

const PageTemplate = ({
  data: {
    page: { date, path, yoast_meta: yoast, acf },
  },
}) => {
  const blogFields = postInfo.get('klantverhalen')

  console.log('blogFields', blogFields)

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero4
        fields={{ ...{ date, readtime: acf.meta.readtime }, ...acf.banner }}
      />

      <div className="container mt-4 mt-lg-5">
        <BackLink to={blogFields.path}>
          {blogFields.acf.meta.back_button}
        </BackLink>
        <div className="mt-5 d-lg-flex d-md-flex d-sm-block d-xs-block">
          <div className="col-lg-8 px-0 pr-md-4 col-md-6 col-sm-12 col-xs-12 pr-lg-5">
            <div className="pr-lg-5">
              <Content content={acf.meta.description} />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 pl-lg-2">
            <ParseContent content={acf.sidebar.description} />
            
            <Teammate
              id={acf.sidebar.cta}
              type={1}
            />
          </div>
        </div>
        
        <div className="py-lg-5 mt-5">
          <div className="pt-5">
            <ParseContent
              content={blogFields.acf.meta.more_posts}
            />

            <HeadingLayout direction="right-right">
              <BlogContainer
                postType="klantverhalen"
                settings={{
                  limit: null,
                  postIds: acf.related.articles
                }}
              >
                <BlogConsumer>
                  {({ posts }) => {
                    return (
                      <>
                        <BlogOverview posts={posts} />
                      </>
                    )
                  }}
                </BlogConsumer>
              </BlogContainer>
            </HeadingLayout>
          </div>
        </div>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpKlantverhalen(wordpress_id: { eq: $wordpress_id }) {
      date(locale: "nl_NL", formatString: "DD-MM-YYYY")
      title
      path
      wordpress_id

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        meta {
          readtime
          description
        }

        sidebar {
          description
          cta
        }

        related {
          articles
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
